<template>
  <Swiper
    :modules="modules"
    :slides-per-view="1"
    :space-between="16"
    :speed="600"
    effect="fade"
    :fadeEffect="{
      crossFade: true
    }"
    :pagination="{ clickable: true }"
    class="relative m-0 mx-auto h-auto w-full p-0 text-left lg:-mt-8"
  >
    <SwiperSlide v-for="(testimonial, idx) in data" :key="idx">
      <div class="grid-default lg:pb-32 lg:pt-20">
        <div
          class="col-span-6 flex flex-col"
          :class="[elevated ? 'lg:col-span-12' : 'lg:col-start-4 lg:col-end-13']"
        >
          <div
            class="mb-6 text-lg font-light text-blue-500 lg:order-2"
            :class="[!elevated && 'lg:text-3xl']"
            v-html="testimonial.body"
          />
          <div
            class="flex flex-col lg:order-1 lg:mb-20 lg:flex-row lg:items-center"
            :class="[elevated ? 'lg:order-2 lg:mb-9' : ' lg:mb-20']"
          >
            <div
              v-if="testimonial.name"
              class="mb-[2px] flex items-center gap-3 text-lg font-medium text-blue-800"
            >
              <span
                class="inline-block h-[25px] w-[25px] rounded-full border-[5px] border-orange"
              ></span>
              {{ testimonial.name }}
            </div>
            <div v-if="testimonial.company" class="mb-6 ml-9 text-sm text-blue-700 lg:mb-0 lg:ml-3">
              {{ testimonial.company }}
            </div>
          </div>
        </div>
      </div>
    </SwiperSlide>
    <SwiperNav
      class="absolute z-1 hidden lg:block"
      :class="[elevated ? 'lg:bottom-0 lg:left-0' : ' lg:left-0 lg:top-24 ']"
    />
  </Swiper>
</template>

<script setup lang="ts">
import { A11y, Pagination, EffectFade } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import type { TestimonialRecord } from 'types/generated'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'

defineProps({
  data: {
    type: Array as PropType<TestimonialRecord[]>,
    default: () => []
  },
  elevated: {
    type: Boolean,
    default: false
  }
})

const modules = [A11y, Pagination, EffectFade]
</script>

<style lang="postcss">
.swiper-pagination {
  @apply !bottom-0 !left-auto !right-0 !w-fit;
}

.swiper-pagination-bullet {
  --swiper-pagination-bullet-width: 12px;
  --swiper-pagination-bullet-size: 12px;
  --swiper-theme-color: theme(colors.blue.700);
  --swiper-pagination-bullet-inactive-color: theme(colors.blue.100);
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 8px;
  --swiper-pagination-bullet-vertical-gap: 16px;
  @apply !rounded-full !opacity-100;
}
</style>
