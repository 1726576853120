<template>
  <div>
    <CircleButton
      class="rotate-180"
      :class="{
        'pointer-events-none cursor-default opacity-50': swiper.isBeginning && !isLoop
      }"
      color="blue-outline"
      :is-disabled="swiper.isBeginning && !isLoop"
      :disabled="swiper.isBeginning && !isLoop"
      :title="$t('swiper.previous')"
      icon-name="arrow-right"
      icon-color="#314863"
      outline-color="#DDE1D9"
      @click="swiper.slidePrev()"
    />
    <CircleButton
      color="blue-outline"
      :class="{
        'pointer-events-none cursor-default opacity-50': swiper.isEnd && !isLoop
      }"
      :is-disabled="swiper.isEnd && !isLoop"
      :disabled="swiper.isEnd && !isLoop"
      :title="$t('swiper.next')"
      class="ml-4"
      icon-name="arrow-right"
      icon-color="#314863"
      outline-color="#DDE1D9"
      @click="swiper.slideNext()"
    />
  </div>
</template>

<script setup lang="ts">
import { useSwiper } from 'swiper/vue'

const swiper = useSwiper()

defineProps({
  isLoop: {
    type: Boolean,
    default: false
  }
})
</script>
